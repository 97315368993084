import React from "react"
import { Link } from "gatsby"
import styled, { css } from "styled-components"

import { minD, maxD } from "../../../../assets/styles/helpers/devices"
import { UnstyledList } from "../../common/essentials"

const NavElementsContainer = styled.div`
  @media ${maxD.tabletL} {
    --nav-top-padding: 105px;

    position: fixed;
    width: 100vw;
    height: 100vh;

    top: 0;
    left: 0;
    z-index: -2;

    padding: var(--nav-top-padding) calc((100% - 690px) / 2) 0;
    background-color: #fff;

    transform: ${({ isMobileActive }) =>
      isMobileActive ? "translateX(0)" : "translateX(-100%)"};
    transition: transform 800ms cubic-bezier(0.19, 1, 0.22, 1);
  }

  @media ${maxD.tablet} {
    padding: var(--nav-top-padding) calc((100% - 500px) / 2) 0;
  }

  @media ${maxD.mobileL} {
    --nav-top-padding: 95px;

    padding: var(--nav-top-padding) 25px 0;
  }
`

const NavElements = styled(UnstyledList)`
  @media ${minD.tabletL} {
    display: flex;
    justify-content: center;

    padding: 0 10px;
  }

  @media ${minD.laptop} {
    padding-left: 60px;
    padding-right: 0;
  }

  @media ${maxD.tabletL} {
    height: calc(100vh - var(--nav-top-padding));
    padding-bottom: calc(var(--nav-top-padding) * 0.5);
    overflow: auto;
  }
`

const NavElement = styled.li`
  --element-padding-vertical: 17px;
  --element-padding-horizontal: 30px;

  --element-font-size: 0.92rem;

  @media ${maxD.laptopL} {
    --element-font-size: 0.85rem;
  }

  @media ${maxD.laptop} {
    --element-font-size: 0.75rem;
  }

  @media ${maxD.tabletL} {
    --element-font-size: 1.15rem;
  }

  @media ${maxD.mobileS} {
    --element-font-size: 1rem;
  }
`

const navLinkSharedStyles = css`
  display: block;
  position: relative;

  font-size: var(--element-font-size);

  @media ${maxD.tabletL} {
    font-weight: 600;

    &::after {
      position: absolute;
      content: "";

      height: 2px;

      left: 0;
      bottom: 0;

      background-color: var(--mobile-nav-separator-gray);
    }
  }
`

const NavLink = styled(Link)`
  padding: var(--element-padding-vertical) calc(var(--element-padding-horizontal) * 0.8);
  border-radius: 30px;

  transition: color 200ms, background-color 200ms;

  @media ${minD.tabletL} {
    text-align: center;

    &:hover {
      color: #fff;
      background-color: var(--main-green);
    }
  }

  @media ${maxD.desktop} {
    padding: calc(var(--element-padding-vertical) * 0.6)
      calc(var(--element-padding-horizontal) * 0.6);
  }

  @media ${maxD.laptopL} {
    padding: calc(var(--element-padding-vertical) * 0.3)
      calc(var(--element-padding-horizontal) * 0.3);
  }

  ${navLinkSharedStyles}

  @media ${maxD.tabletL} {
    padding: 0.8rem 0;

    &::after {
      width: 3.2rem;
    }
  }
`

const NavSpan = styled.span`
  padding: var(--element-padding-vertical) calc(var(--element-padding-horizontal) * 0.8);
  border-radius: 30px;
  cursor: pointer;

  transition: color 200ms, background-color 200ms;

  @media ${minD.tabletL} {
    text-align: center;

    &:hover {
      color: #fff;
      background-color: var(--main-green);
    }
  }

  @media ${maxD.desktop} {
    padding: calc(var(--element-padding-vertical) * 0.6)
      calc(var(--element-padding-horizontal) * 0.6);
  }

  @media ${maxD.laptopL} {
    padding: calc(var(--element-padding-vertical) * 0.3)
      calc(var(--element-padding-horizontal) * 0.3);
  }

  ${navLinkSharedStyles}

  @media ${maxD.tabletL} {
    padding: 0.8rem 0;

    &::after {
      width: 3.2rem;
    }
  }
`

const SubNavContainer = styled.div`
  @media ${minD.tabletL} {
    position: absolute;
    top: calc(100% + 1rem);

    padding-top: 1.4rem;

    visibility: hidden;
    opacity: 0;
    transform: translateY(0.8rem);

    transition: transform 200ms, opacity 200ms, visibility 1ms 200ms;

    ${NavElement}:focus > &,
    ${NavElement}:focus-within > &, 
    ${NavElement}:hover > &,
    &:hover {
      visibility: visible;
      opacity: 1;
      transform: translateY(-1.2rem);

      transition: transform 200ms 101ms, opacity 200ms 101ms,
        visibility 1ms 100ms;
    }
  }
`

const SubNav = styled(UnstyledList)`
  @media ${minD.tabletL} {
    padding: calc(var(--element-padding-horizontal) * 0.7)
      calc(var(--element-padding-horizontal) * 1.5)
      calc(var(--element-padding-horizontal) * 0.7)
      calc(var(--element-padding-horizontal) * 0.7);
    border-radius: 10px;

    background-color: var(--main-green);

    &::before {
      position: absolute;
      content: "";

      top: 0.3rem;
      left: 1.5rem;

      border: 0.6rem solid transparent;
      border-bottom-color: var(--main-green);
    }
  }

  @media ${maxD.tabletL} {
    padding: 0.5rem 0 0 3rem;
  }
`

const SubNavElement = styled.li`
  @media ${minD.tabletL} {
    white-space: pre;

    &:not(:last-of-type) {
      padding: 0 0 calc(var(--element-padding-horizontal) * 0.3);
    }
  }

  @media ${maxD.tabletL} {
    padding-bottom: 0.5rem;
  }
`

const SubNavLink = styled(Link)`
  @media ${minD.tabletL} {
    color: #fff;

    transition: opacity 200ms ease;

    &:hover {
      opacity: 0.7;
    }
  }

  ${navLinkSharedStyles}

  @media ${maxD.tabletL} {
    padding: 0.5rem 0;

    &::after {
      width: 2rem;
    }
  }
`

const NavList = ({ isActive, items }) => (
  <NavElementsContainer isMobileActive={isActive}>
    <NavElements>
      {items &&
        items.length !== 0 &&
        items.map(
          ({ path, label, id, parentId, childItems: { nodes: subNavItems } }) =>
            !parentId && (
              <NavElement key={id}>
                {path === '#' || path === 'https://#' ? 
                  <NavSpan>{label}</NavSpan>
                :
                  <NavLink to={path}>{label}</NavLink>
                }
                {subNavItems && subNavItems.length !== 0 && (
                  <SubNavContainer>
                    <SubNav>
                      {subNavItems.map(({ path, label, id }) => (
                        <SubNavElement key={id}>
                          <SubNavLink to={path}>{label}</SubNavLink>
                        </SubNavElement>
                      ))}
                    </SubNav>
                  </SubNavContainer>
                )}
              </NavElement>
            )
        )}
    </NavElements>
  </NavElementsContainer>
)

export { NavList }
